/**@jsx jsx*/
import React, { useReducer, useMemo } from "react"
import { useGlobalState, useGlobalDispatch } from "../utilities/globalContext"
import { navigate } from "gatsby"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import axios from "axios"

import Layout from "../components/layout"
import { Col } from "boostly-ui2"
import { TitleSecondary, TitlePrimary } from "../components/titles"
import { ButtonPrimary } from "../components/buttons"
import { serverURL } from "../../variables"
import FloatInput from "../components/floatInput"
import { medMT } from "../components/spacing"

const Warning = styled.div`
  color: tomato;
`
const Input = styled(FloatInput)`
  width: 90%;
  max-width: 500px;
`

const signupURL = `${serverURL}/api/users`
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_EMAIL":
      return {
        ...state,
        email: action.email,
        allowSubmit: action.email && state.password && state.name,
      }
    case "SET_PASSWORD":
      return {
        ...state,
        password: action.password,
        allowSubmit: action.password && state.password && state.name,
      }
    case "SET_NAME":
      return {
        ...state,
        name: action.name,
        allowSubmit: action.name && state.password && state.name,
      }
    case "SET_MESSAGE":
      return {
        ...state,
        message: action.message,
      }
    default:
      return state
  }
}

const Pay = () => {
  const globalDispatch = useGlobalDispatch()
  const [state, dispatch] = useReducer(reducer, {
    email: "",
    password: "",
    name: "",
    allowSubmit: false,
    message: "",
  })

  const emailInput = useMemo(
    () => (
      <Input
        type="text"
        placeholder="email"
        onChange={e => dispatch({ type: "SET_EMAIL", email: e.target.value })}
      />
    ),
    []
  )
  const passwordInput = useMemo(
    () => (
      <Input
        type="password"
        placeholder="password"
        onChange={e =>
          dispatch({ type: "SET_PASSWORD", password: e.target.value })
        }
      />
    ),
    []
  )
  const nameInput = useMemo(
    () => (
      <Input
        type="text"
        placeholder="vendor name"
        onChange={e => dispatch({ type: "SET_NAME", name: e.target.value })}
      />
    ),
    []
  )

  const handleSubmit = async e => {
    e.preventDefault()
    if (state.allowSubmit) {
      const { email, password, name } = state
      try {
        let res = await axios.post(signupURL, {
          email: email,
          password: password,
          name: name,
        })
        const user = res.data
        globalDispatch({ type: "SET_USER", user })
        navigate("/dashboard")
      } catch (e) {
        dispatch({ type: "SET_MESSAGE", message: e.response.data.message })
      }
    }
  }

  return (
    <Layout>
      <form
        css={css`
          z-index: 2;
          grid-column-start: 2;
          grid-row-start: 2;
          padding-top: 40px;
          @media (max-width: 500px) {
            padding-top: 20px;
          }
        `}
        onSubmit={handleSubmit}
      >
        <Col
          css={css`
            height: 400px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
          `}
          x
          space="between"
        >
          <TitlePrimary css={medMT}>You're gonna love this.</TitlePrimary>
          {emailInput}
          {passwordInput}
          {nameInput}
          <ButtonPrimary
            css={css`
              align-self: start;
            `}
            disabled={!state.allowSubmit}
          >
            submit
          </ButtonPrimary>
          <Warning>{state.message && state.message}</Warning>
        </Col>
      </form>
    </Layout>
  )
}

export default Pay
